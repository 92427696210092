function LogoWordmark({className}){
    return (
        <>
            <svg className={className}  viewBox="0 0 477 72" version="1.1" xmlns="http://www.w3.org/2000/svg" >
    <g className="fill-neutral-950 dark:fill-white" transform="matrix(1,0,0,1,-243.52,-11.39)">
        <g>
            <path d="M244.44,31.86C244.44,27.84 245.48,24.28 247.55,21.2C249.63,18.12 252.5,15.71 256.18,13.98C259.86,12.25 264.12,11.39 268.96,11.39C273.8,11.39 277.65,12.21 281.08,13.84C284.51,15.48 287.18,17.8 289.1,20.82C291.02,23.84 292.04,27.42 292.17,31.57L280.1,31.57C279.97,28.61 278.9,26.3 276.89,24.64C274.88,22.97 272.17,22.14 268.78,22.14C265.07,22.14 262.1,22.97 259.87,24.64C257.64,26.31 256.52,28.59 256.52,31.48C256.52,33.93 257.19,35.87 258.55,37.28C259.9,38.7 262.02,39.75 264.92,40.44L275.77,42.89C281.68,44.15 286.08,46.3 288.98,49.35C291.87,52.4 293.32,56.5 293.32,61.66C293.32,65.87 292.28,69.58 290.21,72.79C288.14,76 285.2,78.47 281.39,80.19C277.59,81.92 273.14,82.78 268.04,82.78C262.94,82.78 258.95,81.95 255.31,80.28C251.66,78.61 248.82,76.27 246.77,73.25C244.73,70.23 243.64,66.68 243.52,62.59L255.59,62.59C255.65,65.48 256.8,67.76 259.03,69.43C261.26,71.1 264.3,71.93 268.13,71.93C271.96,71.93 275.35,71.1 277.7,69.43C280.06,67.76 281.24,65.55 281.24,62.78C281.24,60.39 280.61,58.49 279.35,57.07C278.09,55.65 276.05,54.66 273.22,54.1L262.28,51.65C256.43,50.39 252,48.11 248.98,44.81C245.96,41.51 244.45,37.19 244.45,31.84L244.44,31.86Z" />
            <path d="M332.01,66.48C331.38,68.69 330.04,70.38 328,71.58C325.96,72.77 323.36,73.37 320.22,73.37C315.94,73.37 312.72,72.08 310.55,69.5C308.82,67.44 307.78,64.61 307.43,61L342.48,60.92L342.48,57.43C342.48,52.52 341.55,48.31 339.7,44.79C337.84,41.27 335.22,38.52 331.82,36.54C328.43,34.56 324.37,33.56 319.65,33.56C314.93,33.56 311.1,34.62 307.58,36.72C304.06,38.83 301.32,41.74 299.37,45.45C297.42,49.16 296.45,53.44 296.45,58.28C296.45,63.12 297.45,67.29 299.47,70.96C301.48,74.64 304.26,77.54 307.81,79.64C311.37,81.75 315.44,82.8 320.03,82.8C326.13,82.8 331.18,81.36 335.17,78.46C339.16,75.57 341.66,71.58 342.67,66.48L332.01,66.48ZM308.71,49.79C309.65,47.52 311.05,45.83 312.91,44.69C314.76,43.56 317.04,43 319.75,43C323.2,43 325.96,43.97 328,45.92C330.04,47.87 331.07,50.45 331.07,53.66L307.64,53.66C307.87,52.24 308.23,50.95 308.71,49.79Z" />
            <path d="M376.6,34.79L376.6,45.45L372.36,45.45C368.15,45.45 364.85,46.57 362.46,48.8C360.07,51.03 358.87,54.41 358.87,58.94L358.87,81.58L347.36,81.58L347.36,35.07L358.21,35.07L358.88,41.95C359.67,40.08 360.86,38.49 362.46,37.15C364.78,35.2 367.8,34.22 371.51,34.22C372.33,34.22 373.15,34.26 373.96,34.32C374.78,34.38 375.66,34.54 376.6,34.79Z" />
            <path d="M397.69,81.57L379.2,34.97L391.46,34.97L399.57,56.38C400.57,59.15 401.44,61.68 402.16,63.97C402.88,66.27 403.4,68.04 403.71,69.3C404.02,67.85 404.58,65.98 405.36,63.69C406.15,61.4 407.04,58.96 408.05,56.38L416.54,34.97L428.43,34.97L409,81.57L397.68,81.57L397.69,81.57Z" />
            <path d="M462.35,66.48C461.72,68.69 460.38,70.38 458.34,71.58C456.29,72.77 453.7,73.37 450.56,73.37C446.28,73.37 443.06,72.08 440.89,69.5C439.16,67.44 438.12,64.61 437.76,61L472.82,60.92L472.82,57.43C472.82,52.52 471.89,48.31 470.03,44.79C468.18,41.27 465.55,38.52 462.16,36.54C458.76,34.56 454.71,33.56 449.99,33.56C445.27,33.56 441.44,34.62 437.92,36.72C434.39,38.83 431.66,41.74 429.71,45.45C427.76,49.16 426.79,53.44 426.79,58.28C426.79,63.12 427.79,67.29 429.8,70.96C431.82,74.64 434.6,77.54 438.15,79.64C441.7,81.75 445.78,82.8 450.37,82.8C456.47,82.8 461.51,81.36 465.51,78.46C469.5,75.57 472,71.58 473.01,66.48L462.35,66.48ZM439.05,49.79C439.99,47.52 441.39,45.83 443.25,44.69C445.1,43.56 447.38,43 450.08,43C453.54,43 456.29,43.97 458.34,45.92C460.38,47.87 461.4,50.45 461.4,53.66L437.97,53.66C438.2,52.24 438.56,50.95 439.05,49.79Z" />
            <path d="M506.94,34.79L506.94,45.45L502.7,45.45C498.48,45.45 495.18,46.57 492.79,48.8C490.4,51.03 489.21,54.41 489.21,58.94L489.21,81.58L477.7,81.58L477.7,35.07L488.55,35.07L489.22,41.95C490.01,40.08 491.2,38.49 492.79,37.15C495.12,35.2 498.14,34.22 501.85,34.22C502.66,34.22 503.48,34.26 504.3,34.32C505.12,34.38 506,34.54 506.94,34.79Z" />
            <path d="M561.18,52.15C559.34,49.84 556.78,48.14 553.49,47.06C556.46,45.8 558.77,44.02 560.42,41.72C562.44,38.93 563.44,35.45 563.44,31.3C563.44,25.52 561.49,20.96 557.59,17.62C553.69,14.29 548.32,12.62 541.46,12.62L514.11,12.62L514.11,81.58L542.03,81.58C546.75,81.58 550.79,80.84 554.15,79.36C557.51,77.88 560.08,75.74 561.84,72.94C563.6,70.15 564.48,66.8 564.48,62.9C564.48,58.5 563.38,54.91 561.18,52.15ZM526.18,23.28L540.43,23.28C543.88,23.28 546.52,24.07 548.35,25.64C550.17,27.21 551.09,29.48 551.09,32.43C551.09,35.38 550.2,37.89 548.44,39.55C546.68,41.22 544.14,42.05 540.8,42.05L526.18,42.05L526.18,23.28ZM549.39,68.46C547.56,70.1 544.92,70.92 541.46,70.92L526.18,70.92L526.18,52.24L541.46,52.24C544.86,52.24 547.48,53.04 549.34,54.65C551.19,56.25 552.12,58.5 552.12,61.39C552.12,64.47 551.21,66.83 549.39,68.46Z" />
            <path d="M567.72,58.18C567.72,53.34 568.79,49.08 570.93,45.4C573.07,41.72 576.02,38.84 579.8,36.77C583.57,34.69 587.85,33.66 592.63,33.66C597.41,33.66 601.76,34.7 605.51,36.77C609.25,38.84 612.19,41.72 614.33,45.4C616.47,49.08 617.54,53.34 617.54,58.18C617.54,63.02 616.47,67.28 614.33,70.96C612.19,74.64 609.25,77.52 605.51,79.59C601.77,81.67 597.48,82.7 592.63,82.7C587.78,82.7 583.58,81.66 579.8,79.59C576.03,77.51 573.07,74.64 570.93,70.96C568.79,67.28 567.72,63.02 567.72,58.18ZM579.23,58.18C579.23,61.01 579.81,63.49 580.98,65.63C582.14,67.77 583.72,69.44 585.7,70.63C587.68,71.83 589.99,72.42 592.63,72.42C595.27,72.42 597.6,71.82 599.61,70.63C601.62,69.44 603.19,67.77 604.33,65.63C605.46,63.49 606.03,61.01 606.03,58.18C606.03,55.35 605.46,52.79 604.33,50.68C603.2,48.57 601.62,46.92 599.61,45.73C597.6,44.54 595.27,43.94 592.63,43.94C589.99,43.94 587.68,44.54 585.7,45.73C583.72,46.93 582.15,48.58 580.98,50.68C579.81,52.79 579.23,55.29 579.23,58.18Z" />
            <path d="M621.58,58.18C621.58,53.34 622.65,49.08 624.79,45.4C626.93,41.72 629.88,38.84 633.66,36.77C637.43,34.69 641.71,33.66 646.49,33.66C651.27,33.66 655.62,34.7 659.37,36.77C663.11,38.84 666.05,41.72 668.19,45.4C670.33,49.08 671.4,53.34 671.4,58.18C671.4,63.02 670.33,67.28 668.19,70.96C666.05,74.64 663.11,77.52 659.37,79.59C655.63,81.67 651.34,82.7 646.49,82.7C641.64,82.7 637.44,81.66 633.66,79.59C629.89,77.51 626.93,74.64 624.79,70.96C622.65,67.28 621.58,63.02 621.58,58.18ZM633.09,58.18C633.09,61.01 633.67,63.49 634.84,65.63C636,67.77 637.58,69.44 639.56,70.63C641.54,71.83 643.85,72.42 646.49,72.42C649.13,72.42 651.46,71.82 653.47,70.63C655.48,69.44 657.05,67.77 658.19,65.63C659.32,63.49 659.89,61.01 659.89,58.18C659.89,55.35 659.32,52.79 658.19,50.68C657.06,48.57 655.48,46.92 653.47,45.73C651.46,44.54 649.13,43.94 646.49,43.94C643.85,43.94 641.54,44.54 639.56,45.73C637.58,46.93 636.01,48.58 634.84,50.68C633.67,52.79 633.09,55.29 633.09,58.18Z" />
            <path d="M687.77,81.57L676.36,81.57L676.36,11.39L687.77,11.39L687.77,54.5L705.88,34.97L720.31,34.97L702.58,53.46L719.94,81.57L706.73,81.57L694.56,61.95L687.77,69.02L687.77,81.57Z" />
        </g>
    </g>
</svg>
        </>
    );
}
export default LogoWordmark;