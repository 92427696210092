import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import './output.css'; // Import the output.css file

import LogoIcon from './assets/LogoIcon';
import LogoWordmark from './assets/LogoWordmark';

import { HomeIcon, ComputerDesktopIcon, UsersIcon, ServerStackIcon, Cog8ToothIcon, EllipsisHorizontalIcon, PlusCircleIcon } from '@heroicons/react/24/outline';

function NavItem({path, label, icon, isActive}) {
  const location = useLocation();
  return (
    <Link to={path} className={`group/nav-item w-full flex place-content-center 2xl:place-content-start group-hover/sidebar:place-content-start ${location.pathname === path ? '--active' : ''}`}>
      <div className='flex group-hover/nav-item:bg-neutral-100 dark:group-hover/nav-item:bg-neutral-800 gap-3 py-2 px-3 rounded group-[.--active]/nav-item:bg-violet-100 dark:group-[.--active]/nav-item:bg-violet-950 duration-150'>
        {React.createElement(icon, {className: 'w-6 text-neutral-400 dark:text-neutral-500 stroke-2 group-[.--active]/nav-item:text-violet-600'})}
        <span className='hidden 2xl:block group-hover/sidebar:block text-black dark:text-white'>{label}</span>
      </div>
    </Link>
  )
}

function TopBar() {
  return (
    <div className='relative flex h-24 w-full items-center'>
      <div className='container flex gap-8 mx-auto px-4'>
        <input type='text' className='w-full h-12 bg-neutral-100 dark:bg-neutral-800 border-2 border-neutral-100 dark:border-neutral-800 rounded px-4' placeholder='Search for Sites, Clients, or Servers' />
        <button className='flex items-center px-6 gap-2 relative bg-violet-600 h-12 w-auto rounded'>
          <PlusCircleIcon className='w-7 text-white' />
          <span className='block relative whitespace-nowrap text-white'>New Site</span>
        </button>
      </div>
    </div>
  )
}

function App() {

  const footerLinks = [
    {label: 'Terms', url: '#'},
    {label: 'Privacy', url: '#'},
    {label: 'Support', url: '#'},
    {label: 'Changelog', url: '#'},
  ];
  const userPictureUrl = 'https://r2.serverbook.app/user-image/9e1a4260ea970fb37721bd9c968e2db8-medium.jpg';

  return (
    <div className="App bg-white dark:bg-neutral-900">
      <div className='grid relative z-10 grid-cols-layout-narrow 2xl:grid-cols-layout-wide min-h-screen'>
        <div className='group/sidebar block z-50 fixed left-0 top-0 w-[100px] 2xl:w-[300px] hover:w-[300px] h-screen bg-white dark:bg-neutral-900 border-r-2 border-neutral-100 dark:border-neutral-800 overflow-hidden transition-[width] duration-150 ease-in-out'>

          <div className='block absolute w-[100px] 2xl:w-[300px] group-hover/sidebar:w-[300px] h-full'>

            <div className='flex items-center gap-3 h-24 2xl:px-8 place-content-center 2xl:place-content-start group-hover/sidebar:place-content-start group-hover/sidebar:px-8'>
              <LogoIcon className='block h-[24px]' />
              <LogoWordmark className='h-[18px] hidden 2xl:block group-hover/sidebar:block' />
            </div>
            <nav className='py-8 flex flex-col gap-1 2xl:px-8 group-hover/sidebar:px-8'>
              <NavItem path='/' label='Home' icon={HomeIcon} isActive={true} />
              <NavItem path='/sites' label='Sites' icon={ComputerDesktopIcon} />
              <NavItem path='/clients' label='Clients' icon={UsersIcon} />
              <NavItem path='/servers' label='Servers' icon={ServerStackIcon} />
              <NavItem path='/settings' label='Settings' icon={Cog8ToothIcon} />
            </nav>

            <div class="block absolute bottom-0 left-0 py-8 w-full 2xl:px-8 group-hover/sidebar:px-8">
              <Link to='/account' className='relative flex w-full items-center gap-4 mb-6 before:block before:absolute before:bg-black dark:before:bg-white before:w-[calc(100%+8px)] before:h-[calc(100%+8px)] before:top-[-4px] before:left-[-4px] before:opacity-0 before:rounded-[8px] before:duration-150 hover:before:opacity-5 place-content-center 2xl:place-content-start group-hover/sidebar:place-content-start'>
                <div class="relative w-12 h-12 bg-neutral-100 bg-cover bg-center ring-2 ring-black ring-opacity-10 ring-inset rounded shrink-0" style={{backgroundImage: `url(${userPictureUrl})`}}></div>
                <div className='relative flex-col gap-0 grow-0 w-full min-w-0 hidden 2xl:flex group-hover/sidebar:flex'>
                  <span className='relative block text-[14px] leading-[14px] py-1 text-neutral-600 dark:text-neutral-400 w-full truncate'>MJS Media</span>
                  <span className='relative block text-[16px] leading-[16px] py-1 dark:text-white w-full truncate'>Ashley Gorringe</span>
                </div>
              </Link>
              <div className='relative hidden 2xl:block group-hover/sidebar:block'>
                <ul className='mb-1 ml-[-3px] mr-[-3px] flex gap-1'>
                  {footerLinks.map((link, index) => (
                    <li key={index} className='relative block'>
                      <a href={link.url} className='relative block p-[3px] text-[12px] leading-[12px] text-neutral-400 dark:text-neutral-500 rounded hover:bg-neutral-100 hover:text-black dark:hover:bg-neutral-700 dark:hover:text-neutral-300 duration-150'>
                        {link.label}
                      </a>
                    </li>
                  ))}
                </ul>
                <span className='text-[10px] leading-[10px] text-neutral-300 dark:text-neutral-600'>&copy; 2024 Dandylion Technologies.</span>
              </div>
              <div className='relative flex justify-center place-content-center w-full h-[46px] 2xl:hidden group-hover/sidebar:hidden'>
                  <EllipsisHorizontalIcon className='block relative w-10 text-neutral-300 dark:text-neutral-600'/>
              </div>
            </div>

          </div>

        </div>
        <div className='col-start-2'>
          <Routes>
            <Route path='/' element={
              <>
                <TopBar />
                <section className='py-16'>
                  <div className='container mx-auto px-4'>
                    <h1 className='text-4xl font-bold mb-3'>Good evening, Ashley!</h1>
                    <p className='text-xl'>Keep track of your Servers, Sites, and Domains at a glance.</p>
                  </div>
                </section>
              </>
            } />
            <Route path='/sites' element={
              <>
                <TopBar />
                <section className='py-16'>
                  <div className='container mx-auto px-4'>
                    <h1 className='text-4xl font-bold mb-3'>Sites</h1>
                  </div>
                </section>
              </>
            } />
            <Route path='/clients' element={
              <>
                <TopBar />
                <section className='py-16'>
                  <div className='container mx-auto px-4'>
                    <h1 className='text-4xl font-bold mb-3'>Clients</h1>
                  </div>
                </section>
              </>
            } />
            <Route path='/servers' element={
              <>
                <TopBar />
                <section className='py-16'>
                  <div className='container mx-auto px-4'>
                    <h1 className='text-4xl font-bold mb-3'>Servers</h1>
                  </div>
                </section>
              </>
            } />
            <Route path='/settings' element={
              <>
                <section className='py-16'>
                  <div className='container mx-auto px-4'>
                    <h1 className='text-4xl font-bold mb-3'>Settings</h1>
                  </div>
                </section>
              </>
            } />
            <Route path='/account' element={
              <>
                <section className='py-16'>
                  <div className='container mx-auto px-4'>
                    <h1 className='text-4xl font-bold mb-3'>Account</h1>
                  </div>
                </section>
              </>
            } />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;
