function LogoIcon({className}){
    return (
        <>
            <svg className={className} viewBox="0 0 199 95" xmlns="http://www.w3.org/2000/svg" >
                <path className="fill-fuchsia-600" d="m55.88 13.62-2.77 6.65L29.9 10.6l2.77-6.65c.9-2.15 3.37-3.17 5.53-2.28l15.41 6.42c2.15.9 3.17 3.37 2.28 5.53h-.01Z"  transform="translate(.002 -.003)"/>
                <path className="fill-fuchsia-600" d="M-1.04 35.03h58.28v25.14H-1.04z" transform="rotate(-67.38 28.104 47.605)"/>
                <path className="fill-fuchsia-600" d="m3.1 74.95 23.21 9.67-2.77 6.65a4.241 4.241 0 0 1-5.53 2.28L2.6 87.13A4.241 4.241 0 0 1 .32 81.6l2.77-6.65h.01Z"  transform="translate(.002 -.003)"/>
                <path className="fill-purple-600" d="m87.45 7.93-1.27 7.09-24.74-4.45 1.27-7.09c.41-2.3 2.61-3.82 4.91-3.41l16.43 2.95c2.3.41 3.82 2.61 3.41 4.91h-.01Z"  transform="translate(.002 -.003)"/>
                <path className="fill-purple-600" d="M38.5 34.53h58.28v25.14H38.5z" transform="rotate(-79.81 67.63 47.096)"/>
                <path className="fill-purple-600" d="m49.1 79.18 24.74 4.45-1.27 7.09a4.225 4.225 0 0 1-4.91 3.41l-16.43-2.95a4.225 4.225 0 0 1-3.41-4.91l1.27-7.09h.01Z"  transform="translate(.002 -.003)"/>
                <path className="fill-violet-600" d="M192.33.58H99.26c-3.67 0-6.65 2.97-6.65 6.65v13.3c0 3.67 2.97 6.65 6.65 6.65h93.07c3.67 0 6.65-2.98 6.65-6.65V7.22c0-3.67-2.97-6.65-6.65-6.65v.01Zm-23.27 18.28c-2.75 0-4.99-2.23-4.99-4.98 0-2.75 2.24-4.99 4.99-4.99s4.98 2.23 4.98 4.99c0 2.76-2.23 4.98-4.98 4.98Zm13.3 0c-2.75 0-4.99-2.23-4.99-4.98 0-2.75 2.24-4.99 4.99-4.99s4.98 2.23 4.98 4.99c0 2.76-2.23 4.98-4.98 4.98ZM192.33 33.81H99.26c-3.67 0-6.65 2.97-6.65 6.65v13.3c0 3.67 2.97 6.65 6.65 6.65h93.07c3.67 0 6.65-2.97 6.65-6.65v-13.3a6.64 6.64 0 0 0-6.65-6.65Zm-23.27 18.28c-2.75 0-4.99-2.23-4.99-4.98 0-2.75 2.24-4.98 4.99-4.98a4.98 4.98 0 0 1 0 9.96Zm13.3 0c-2.75 0-4.99-2.23-4.99-4.98 0-2.75 2.24-4.98 4.99-4.98a4.98 4.98 0 0 1 0 9.96ZM192.33 67.05H99.26c-3.67 0-6.65 2.97-6.65 6.65V87c0 3.67 2.97 6.65 6.65 6.65h93.07c3.67 0 6.65-2.98 6.65-6.65V73.7c0-3.67-2.97-6.65-6.65-6.65Zm-23.27 18.28c-2.75 0-4.99-2.23-4.99-4.98 0-2.75 2.24-4.99 4.99-4.99s4.98 2.23 4.98 4.99c0 2.76-2.23 4.98-4.98 4.98Zm13.3 0c-2.75 0-4.99-2.23-4.99-4.98 0-2.75 2.24-4.99 4.99-4.99s4.98 2.23 4.98 4.99c0 2.76-2.23 4.98-4.98 4.98Z"  transform="translate(.002 -.003)"/>
            </svg>
        </>
    );
}
export default LogoIcon;